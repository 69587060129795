import React from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Trans, useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import { HeroState } from '../components/hero/hero-context';
import ContentColumn from '../components/content-column/content-column';
import PageWrapper from '../components/page-wrapper/page-wrapper';
import NewsletterBox from '../components/newsletter-box/newsletter-box';

const AboutPage = () => {
  const { t } = useTranslation('about');

  return (
    <PageWrapper>
      <SEO title={t('title')} />
      <HeroState title={t('title')} imageName="machine--front-white" />
      <Container>
        <Row>
          <ContentColumn md={{ offset: 1, span: 10 }}>
            <Trans i18nKey="content" ns="about">
              <p>
                Es war einmal vor gar nicht allzu langer Zeit, da stand ein
                Industriedesigner und ehemaliger Schweizer Baristameister
                zusammen mit einem kleinen Team aus Ingenieuren vor dem, was
                einmal eine Maschine war. Alle Einzelteile, jede Schraube, jedes
                Material und jede mechanische Komponente liegen vor ihnen. Sie
                analysieren und diskutieren: Was ist gut? Was könnte besser
                sein? Und wo gibt es Schwächen?
              </p>
              <p>
                Bei der Maschine, die dort in ihren Einzelteilen liegt, handelt
                es sich um eine traditionelle Handhebelmaschine – den Ursprung
                des ersten Espresso und der Grund für seinen Namen. Mit viel
                Wertschätzung für die Tradition und die Sinnlichkeit einer
                solchen Maschine befinden sie: nicht mehr zeitgemäß. Zu
                unkomfortabel, zu launenhaft, zu wenig nachhaltig.
              </p>
              <h2>Durchdachtes und innovativ Gemachtes.</h2>
              <p>
                Was das Team hinter Manument verbindet, ist der Anspruch
                Espresso-Zubereitung voranzutreiben und eine Maschine zu
                entwickeln, die den Bedürfnissen und Anforderungen von heute
                gerecht wird. Zu diesem Zweck setzen sie ihr Wissen, ihre
                Erfahrung und ihre Leidenschaft für guten Espresso ein. Der
                Industriedesigner zum Beispiel kann auf 20 Jahre Barista- und
                Maschinen-Erfahrung zurückblicken. Die Ingenieure des Teams sind
                nicht nur begeisterte Espressotrinker, sondern bringen mit ihren
                Erfahrungen aus dem filigranen Medizintechnik-Feld auch frischen
                Input und neue Materialien ein.
              </p>
              <p>
                In einem intensiven Entwicklungsprozess hat dieses Team im
                schweizerischen Thun jede Mechanik, jede Technik und jede
                Schraube genauestens durchdacht. Mit einem frischen Blick für
                innovative Werkstoffe und Komponenten haben sie eine Maschine
                geschaffen, die von traditionellen Handheblern gelernt hat – um
                es besser zu machen.
              </p>
              <h2>Für das Erlebnis, für die Bohne.</h2>
              <p>
                Die Manument Leva-Maschine, in der Schweiz von Hand gebaut, ist
                ein Stück Ingenieurskunst, das Präzisionsarbeit und Verständnis
                für Espresso vereint. Die intuitive Handhabung der Maschine
                ermöglicht dem anspruchsvollen Barista deutlich mehr Kontrolle
                über den Extraktionsprozess – und damit ein außerordentliches
                Geschmackserlebnis.
              </p>
              <p>
                Von Grund auf neu gedacht sowie mit innovativen Technologien und
                Materialien wurde die Leva-Maschine optimiert, damit sie das
                einzigartige sensorische Erlebnis eines Handheblers verstärkt
                und der hochwertigen Kaffeebohne gerecht zu werden. Das
                Ergebnis? Soll ein Werkzeugkasten sein, der es jedem Barista
                erlaubt, den perfekten Espresso herzustellen. Und wir denken,
                dass uns das gelungen ist.
              </p>
            </Trans>
          </ContentColumn>
        </Row>
      </Container>
      <NewsletterBox />
    </PageWrapper>
  );
};

export default AboutPage;
